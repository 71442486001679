import { FOOT, KNEE } from "../../utils/constants";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "../shared/RouterHelpers";
import { useState } from "react";
import NoAuthApi from "../../api/NoAuthApi";
import Player from "@vimeo/player";
import React from "react";
import toast from "react-hot-toast";

const TrainingPage = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const instructionTextStrings = {
    de: "Sehen Sie das Video bis zum Ende an, um Ihre Schulung abzuschließen",
    en: "Please watch the video to the end to complete your training",
    es: "Mire el video hasta el final para completar su entrenamiento",
    fr: "Veuillez regarder la vidéo jusqu'à la fin pour terminer votre formation",
    ar: "يرجى مشاهدة الفيديو حتى النهاية لإكمال تدريبك",
    da: "Se venligst videoen til slutningen for at fuldføre din træning",
    el: "Παρακαλούμε δείτε το βίντεο μέχρι το τέλος για να ολοκληρώσετε την εκπαίδευσή σας",
    fi: "Katso video loppuun saadaksesi koulutuksesi loppuun",
    it: "Guarda il video fino alla fine per completare la formazione",
    nb: "Se videoen til slutten for å fullføre treningen",
    pl: "Aby ukończyć szkolenie, obejrzyj film do końca",
    pt: "Por favor, assista ao vídeo até o final para completar seu treinamento",
    ptPT: "Por favor, veja o vídeo até ao final para completar o seu treino",
    sv: "Se videon till slutet för att slutföra din träning",
    tr: "Eğitiminizi tamamlamak için lütfen videoyu sonuna kadar izleyin",
    bg: "Моля, изгледайте видеоклипа до края, за да завършите обучението си",
    hr: "Pogledajte video do kraja kako biste dovršili svoj trening",
    hu: "Kérjük, nézze meg a videót a végéig az edzés befejezéséhez",
    ja: "トレーニングを完了するにはビデオを最後までご覧ください",
    ko: "훈련을 완료하려면 영상을 끝까지 시청하세요.",
    nl: "Bekijk de video tot het einde om uw training te voltooien",
    ro: "Vă rugăm să urmăriți videoclipul până la sfârșit pentru a vă finaliza antrenamentul",
    ru: "Пожалуйста, посмотрите видео до конца, чтобы завершить обучение",
    sl: "Oglejte si videoposnetek do konca, da dokončate svoje usposabljanje",
    uk: "Перегляньте відео до кінця, щоб завершити навчання",
    ch: "请完整观看视频以完成您的培训"
  };

  const errorTextStrings = {
    de: "Fehler beim Abschließen der Schulung: Seriennummer oder Validierungs-Token fehlt",
    en: "Error completing training: missing serial number or validation token",
    es: "Error al completar el entrenamiento: falta el número de serie o el token de validación",
    fr: "Erreur pour terminer la formation : numéro de série ou jeton de validation manquant",
    ar: "خطأ في إكمال التدريب: الرقم التسلسلي أو رمز التحقق مفقود",
    da: "Fejl ved gennemførelse af træning: manglende serienummer eller valideringstoken",
    el: "Σφάλμα ολοκλήρωσης της εκπαίδευσης: λείπει ο σειριακός αριθμός ή το διακριτικό επικύρωσης",
    fi: "Virhe koulutuksen suorittamisessa: sarjanumero tai vahvistustunnus puuttuu",
    it: "Errore durante il completamento dell'addestramento: numero di serie o token di convalida mancante",
    nb: "Feil ved fullføring av opplæring: mangler serienummer eller valideringstoken",
    pl: "Błąd podczas ukończenia szkolenia: brak numeru seryjnego lub tokena weryfikacyjnego",
    pt: "Erro ao concluir o treinamento: número de série ou token de validação ausente",
    ptPT: "Erro ao concluir a formação: número de série ou token de validação em falta",
    sv: "Fel när utbildningen slutfördes: serienummer eller valideringstoken saknas",
    tr: "Eğitim tamamlanırken hata oluştu: seri numarası veya doğrulama jetonu eksik",
    bg: "Грешка при завършване на обучението: липсва сериен номер или токен за валидиране",
    hr: "Pogreška pri dovršavanju obuke: nedostaje serijski broj ili token za provjeru valjanosti",
    hu: "Hiba a képzés befejezésekor: hiányzik a sorozatszám vagy az érvényesítési token",
    ja: "トレーニング完了エラー: シリアル番号または検証トークンがありません",
    ko: "학습 완료 중 오류 발생: 일련번호 또는 검증 토큰이 누락되었습니다.",
    nl: "Fout bij het voltooien van de training: ontbrekend serienummer of validatietoken",
    ro: "Eroare la finalizarea instruirii: lipsește numărul de serie sau simbolul de validare",
    ru: "Ошибка завершения обучения: отсутствует серийный номер или токен проверки.",
    sl: "Napaka pri dokončanju usposabljanja: manjka serijska številka ali potrditveni žeton",
    uk: "Помилка завершення навчання: відсутній серійний номер або маркер підтвердження",
    ch: "完成训练时出错：缺少序列号或验证令牌"
};

  const footVideoId = "938352926";
  const kneeVideoId = "936682354";

  const { type } = useParams();
  const [error, setError] = useState();

  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    let videoLink = "";
    // set video based on device type
    switch (type.toLocaleLowerCase()) {
      case FOOT:
        videoLink = footVideoId;
        break;
      case KNEE:
        videoLink = kneeVideoId;
        break;
      default:
        toast.error("No training available for device type: " + type);
        navigate("/");
    }
    const options = {
      id: videoLink,
      loop: false,
      controls: true,
    };
    const player = new Player("trainingVideo", options);
    player.on("ended", updateTrainingStatus);

    // Handle test environment for Brandon's UI testing
    const metas = document.getElementsByTagName("meta");
    if (
      Array.from(metas).some((meta) => meta.getAttribute("name") === "ui-test")
    ) {
      setTimeout(() => updateTrainingStatus(), 5000);
    }

    // set display text based on browser region
    const browserLanguage = query.get("lang");
    switch (browserLanguage) {
      case "de":
        setDisplayText(instructionTextStrings.de);
        break;
      case "es":
        setDisplayText(instructionTextStrings.es);
        break;
      case "fr":
        setDisplayText(instructionTextStrings.fr);
        break;
      case "ar":
        setDisplayText(instructionTextStrings.ar);
        break;
      case "da":
        setDisplayText(instructionTextStrings.da);
        break;
      case "el":
        setDisplayText(instructionTextStrings.el);
        break;
      case "fi":
        setDisplayText(instructionTextStrings.fi);
        break;
      case "it":
        setDisplayText(instructionTextStrings.it);
        break;
      case "nb":
        setDisplayText(instructionTextStrings.nb);
        break;
      case "pl":
        setDisplayText(instructionTextStrings.pl);
        break;
      case "pt":
        setDisplayText(instructionTextStrings.pt);
        break;
      case "ptPT":
        setDisplayText(instructionTextStrings.ptPT);
        break;
      case "sv":
        setDisplayText(instructionTextStrings.sv);
        break;
      case "tr":
        setDisplayText(instructionTextStrings.tr);
        break;
      case "bg":
        setDisplayText(instructionTextStrings.bg);
        break;
      case "hr":
        setDisplayText(instructionTextStrings.hr);
        break;
      case "hu":
        setDisplayText(instructionTextStrings.hu);
        break;
      case "ja":
        setDisplayText(instructionTextStrings.ja);
        break;
      case "ko":
        setDisplayText(instructionTextStrings.ko);
        break;
      case "nl":
        setDisplayText(instructionTextStrings.nl);
        break;
      case "ro":
        setDisplayText(instructionTextStrings.ro);
        break;
      case "ru":
        setDisplayText(instructionTextStrings.ru);
        break;
      case "sl":
        setDisplayText(instructionTextStrings.sl);
        break;
      case "uk":
        setDisplayText(instructionTextStrings.uk);
        break;
      case "ch":
        setDisplayText(instructionTextStrings.ch);
        break;
      case "en":
      default:
        setDisplayText(instructionTextStrings.en);
    }
  }, []);

  const updateTrainingStatus = async () => {
    const errorText =
      "Error updating training status, please contact College Park support";
    const serialNumber = query.get("serial_number");
    const token = query.get("token");
    if (serialNumber && token) {
      try {
        await NoAuthApi.post(
          "has-completed-training",
          { serial_number: serialNumber },
          { headers: { Authorization: "Bearer " + token } }
        );
        try {
          // this function is used by the mobile app to close the training window,
          // but isn't defined here, hence the disable for eslint
          // eslint-disable-next-line
          InvokeTrainingComplete();
        } catch {
          // pass
        }
        return;
      } catch (e) {
        setError(errorText);
      }
    }
    const browserLanguage = query.get("lang");
    switch (browserLanguage) {
      case "de":
        setError(errorTextStrings.de);
        break;
      case "es":
        setError(errorTextStrings.es);
        break;
      case "fr":
        setError(errorTextStrings.fr);
        break;
      case "ar":
        setError(errorTextStrings.ar);
        break;
      case "da":
        setError(errorTextStrings.da);
        break;
      case "el":
        setError(errorTextStrings.el);
        break;
      case "fi":
        setError(errorTextStrings.fi);
        break;
      case "it":
        setError(errorTextStrings.it);
        break;
      case "nb":
        setError(errorTextStrings.nb);
        break;
      case "pl":
        setError(errorTextStrings.pl);
        break;
      case "pt":
        setError(errorTextStrings.pt);
        break;
      case "ptPT":
        setError(errorTextStrings.ptPT);
        break;
      case "sv":
        setError(errorTextStrings.sv);
        break;
      case "tr":
        setError(errorTextStrings.tr);
        break;
      case "bg":
        setError(errorTextStrings.bg);
        break;
      case "hr":
        setError(errorTextStrings.hr);
        break;
      case "hu":
        setError(errorTextStrings.hu);
        break;
      case "ja":
        setError(errorTextStrings.ja);
        break;
      case "ko":
        setError(errorTextStrings.ko);
        break;
      case "nl":
        setError(errorTextStrings.nl);
        break;
      case "ro":
        setError(errorTextStrings.ro);
        break;
      case "ru":
        setError(errorTextStrings.ru);
        break;
      case "sl":
        setError(errorTextStrings.sl);
        break;
      case "uk":
        setError(errorTextStrings.uk);
        break;
      case "ch":
        setError(errorTextStrings.ch);
        break;
      case "en":
      default:
        setError(errorTextStrings.en);
    }
  };

  return (
    <div className="text-center bg-white fill-height">
      {error ? (
        <div className="text-danger">{error}</div>
      ) : (
        <>
          <div id="trainingVideo" className="video"></div>
          <h3 className="m-2 video-text fw-bold">{displayText}</h3>
        </>
      )}
    </div>
  );
};

export default TrainingPage;
